import request from '../utils/request';

// const api = 'api/';
const api = ''
//登录
export function userLogin(data) {
    return request({
        url: api+'qx-api/student/v1/user/login',
        method: 'POST',
        data,
        contentType: true,
        isbody: true,
    });
}
//获取进度
export function getNextQues(params) {
    return request({
        url:  api+'qx-api/student/v1/question/getNextQues',
        method: 'GET',
        params,
    });
}
//保存数据
export function saveSubjectiveAns(data) {
    return request({
        url:  api+'qx-api/student/v1/question/saveSubjectiveAns',
        method: 'POST',
        data,
        contentType: true,
        isbody: true,
    });
}
//获取客观题列表
export function getQuestionObjectiveArray(params) {
    return request({
        url:  api+'qx-api/student/v1/question/getQuestionObjectiveArray',
        method: 'GET',
        params,
    });
}
//保存客观题
export function saveObjectiveAns(data) {
    return request({
        url:  api+'qx-api/student/v1/question/saveObjectiveAns',
        method: 'POST',
        data,
        contentType: true,
        isbody: true,
    });
}
//获取题库
export function getQuesDispense(params) {
    return request({
        url:  api+'qx-api/student/v1/question/getQuestionObjectiveArrayDispense',
        method: 'GET',
        params,
    });
}
